import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpQuizAnswer} from '@/features/experiences-host-video/services/experiences-host-video.http.ts'
import {ExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/types.ts'
import {z} from 'zod'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'

const Response = z.object({
    is_correct: z.boolean(),
    question_answer_id: z.number().int().positive()
})
type Response = z.infer<typeof Response>

interface UseQuizAnswerArgs {
    params: ExperiencesHostVideoRouteParams & {quizId: number}
    mutationOptions?: Omit<UseMutationOptions<Response, unknown, {questionId: number}>, 'mutationFn'>
}
export const useQuizAnswer = ({params, mutationOptions}: UseQuizAnswerArgs) => {
    return useMutation<Response, unknown, {questionId: number}>({
        mutationFn: ({questionId}) =>
            parseAxiosPromise({
                axiosPromise: httpQuizAnswer({urlParams: {...params, questionId}}),
                responseShape: Response,
                onZodError: captureException
            }),
        ...mutationOptions
    })
}
