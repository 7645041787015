import {
    QuizModalAnswerResponse,
    QuizModalFooter,
    QuizModalQuestion,
    QuizModalQuestionHint,
    QuizItemLabelContainer
} from '@/features/experiences-host-video/components/quiz-modal-atoms/QuizModalAtoms.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {CheckCircleBrokenIcon, XCircleIcon} from '@components/ui/icon'
import {ExperiencesHostVideoModalBody} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {ExperiencesHostVideoAnswerItem} from '@/features/experiences-host-video/components/experiences-host-video-answer-item/ExperiencesHostVideoAnswerItem.tsx'
import {TKey} from '@/translations/translationKeys.ts'
import {useQuizAnswer} from '@/features/experiences-host-video/services/useQuizAnswer.ts'
import toast from 'react-hot-toast'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {raise} from '@utilities/helpers.ts'
import {Dispatch, FC, SetStateAction} from 'react'
import {Quiz} from '@/features/experiences-host-video/types.ts'
import {QuizStep} from '@/features/experiences-host-video/components/quiz-modal/QuizModal.tsx'

const optionToTranslationKey = {
    my_self: 'experiences_host_video:quiz:hold_camera:my_self',
    friend: 'experiences_host_video:quiz:hold_camera:friend'
} as const satisfies Record<string, TKey>

interface QuizModalHoldCameraStepProps {
    quiz: Quiz
    setStep: Dispatch<SetStateAction<QuizStep>>
}
export const QuizModalHoldCameraStep: FC<QuizModalHoldCameraStepProps> = ({quiz, setStep}) => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const quizAnswerMutation = useQuizAnswer({
        params: {...routeParams, quizId: quiz.id},
        mutationOptions: {
            onError: () => toast.error(t('errors:default'))
        }
    })
    const mySelfQuestion =
        quiz.questions.find(
            question =>
                question.question_answer.question.key_name == 'who_film' &&
                question.question_answer.answer.key_name == 'my_self'
        ) ?? raise('mySelfQuestion is missing')
    const acquaintanceQuestion =
        quiz.questions.find(
            question =>
                question.question_answer.question.key_name == 'who_film' &&
                question.question_answer.answer.key_name == 'acquaintance'
        ) ?? raise('horizontallyQuestion is missing')

    return (
        <>
            <ExperiencesHostVideoModalBody>
                <Flexbox direction="column" gap={2}>
                    <QuizModalQuestion>{t('experiences_host_video:quiz:hold_camera:question')}</QuizModalQuestion>
                    <QuizModalQuestionHint>{t('experiences_host_video:quiz:tap_options')}</QuizModalQuestionHint>
                </Flexbox>
                <Flexbox direction="column" gap={2} width="100%" align="stretch">
                    <ExperiencesHostVideoAnswerItem
                        label={
                            <QuizItemLabelContainer $isCorrect={quizAnswerMutation.data?.is_correct}>
                                {t(optionToTranslationKey['my_self'])}
                                {quizAnswerMutation.data?.question_answer_id == mySelfQuestion.question_answer.id && (
                                    <>
                                        {quizAnswerMutation.data.is_correct ? (
                                            <CheckCircleBrokenIcon size={20} />
                                        ) : (
                                            <XCircleIcon size={20} />
                                        )}
                                    </>
                                )}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="my_self"
                        name="option"
                        onChange={() => quizAnswerMutation.mutate({questionId: mySelfQuestion.id})}
                        disabled={
                            (quizAnswerMutation.isPending || quizAnswerMutation.isSuccess) &&
                            quizAnswerMutation.data?.question_answer_id != mySelfQuestion.question_answer.id
                        }
                        checked={quizAnswerMutation.data?.question_answer_id == mySelfQuestion.question_answer.id}
                    />
                    <ExperiencesHostVideoAnswerItem
                        label={
                            <QuizItemLabelContainer $isCorrect={quizAnswerMutation.data?.is_correct}>
                                {t(optionToTranslationKey['friend'])}
                                {quizAnswerMutation.data?.question_answer_id ==
                                    acquaintanceQuestion.question_answer.id && (
                                    <>
                                        {quizAnswerMutation.data.is_correct ? (
                                            <CheckCircleBrokenIcon size={20} />
                                        ) : (
                                            <XCircleIcon size={20} />
                                        )}
                                    </>
                                )}
                            </QuizItemLabelContainer>
                        }
                        type="checkbox"
                        value="friend"
                        name="option"
                        onChange={() => quizAnswerMutation.mutate({questionId: acquaintanceQuestion.id})}
                        disabled={
                            (quizAnswerMutation.isPending || quizAnswerMutation.isSuccess) &&
                            quizAnswerMutation.data?.question_answer_id != acquaintanceQuestion.question_answer.id
                        }
                        checked={quizAnswerMutation.data?.question_answer_id == acquaintanceQuestion.question_answer.id}
                    />
                </Flexbox>
                {quizAnswerMutation.isSuccess && (
                    <QuizModalAnswerResponse
                        variant={quizAnswerMutation.data.is_correct ? 'success' : 'danger'}
                        title={t(
                            `experiences_host_video:quiz:${
                                quizAnswerMutation.data.is_correct ? 'answer_correct' : 'answer_incorrect'
                            }`
                        )}
                        paragraph={t('experiences_host_video:quiz:hold_camera:description')}
                    />
                )}
            </ExperiencesHostVideoModalBody>
            <QuizModalFooter progressPercentages={[100, 100, 100, 0, 0]}>
                <Button disabled={!quizAnswerMutation.isSuccess} onClick={() => setStep('staged_people_count')}>
                    {t('experiences_host_video:quiz:next_question')}
                </Button>
            </QuizModalFooter>
        </>
    )
}
