import {
    ExperiencesHostVideoActionCard,
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter,
    ExperiencesHostVideoPageLayout,
    ExperiencesHostVideoParagraph,
    ExperiencesHostVideoTitle
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {ExperiencesHostVideoHeader} from '@/features/experiences-host-video/components/experiences-host-video-header/ExperiencesHostVideoHeader.tsx'
import {ExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/types.ts'
import {FC, useEffect, useState} from 'react'
import {useShowExperiencesHostVideo} from '@/features/experiences-host-video/services/useShowExperiencesHostVideo.ts'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertCircleIcon, AnnotationQuestionIcon, VideoRecorderIcon} from '@components/ui/icon'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import Divider from '@components/ui/divider/Divider.tsx'
import {useTheme} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {QuizModal} from '@/features/experiences-host-video/components/quiz-modal/QuizModal.tsx'
import {RequirementsPerspectiveModal} from '@/features/experiences-host-video/components/requirements-perspective-modal/RequirementsPerspectiveModal.tsx'
import {FriendOnboardingModal} from '@/features/experiences-host-video/components/friend-onboarding-modal/FriendOnboardingModal.tsx'
import {useUpdateStep} from '@/features/experiences-host-video/services/useUpdateStep.ts'
import {UploadClipsIntro} from '@/features/experiences-host-video/components/upload-clips-intro/UploadClipsIntro.tsx'
import {UploadClips} from '@/features/experiences-host-video/components/upload-clips/UploadClips.tsx'
import {StyledChecklistTitle} from '@pages/experiences-host-video-friend/style.ts'
import {Checklist} from '@/features/experiences-host-video/components/checklist/Checklist.tsx'
import toast from 'react-hot-toast'
import {httpStoreQuiz} from '@/features/experiences-host-video/services/experiences-host-video.http.ts'
import {useAsync} from '@hooks/useAsync.ts'

export const ExperiencesHostVideoFriendRoute = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(ExperiencesHostVideoRouteParams)

    if (!urlParams.success) {
        return (
            <ExperiencesHostVideoPageLayout>
                <ExperiencesHostVideoHeader hasBackButton />
                <ErrorBox icon={<AlertCircleIcon />} title={t('errors:invalid_url')} />
            </ExperiencesHostVideoPageLayout>
        )
    }

    return <ExperiencesHostVideoFriendContent urlParams={urlParams.data} />
}

const ExperiencesHostVideoFriendContent: FC<{urlParams: ExperiencesHostVideoRouteParams}> = ({urlParams}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const [quizSlidingModalState, setQuizSlidingModalState] = useState<SlidingModalState>('closed')
    const [requirementsPerspectiveSlidingModalState, setRequirementsPerspectiveSlidingModalState] =
        useState<SlidingModalState>('closed')
    const [onboardingSlidingModalState, setOnboardingSlidingModalState] = useState<SlidingModalState>('closed')
    const showExperiencesHostVideoQuery = useShowExperiencesHostVideo({urlParams})
    const updateStepMutation = useUpdateStep({
        params: urlParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })
    const [quizId, setQuizId] = useState<number | null>(null)
    const storeQuiz = useAsync()
    const status = showExperiencesHostVideoQuery.data?.status.key_name
    const step = showExperiencesHostVideoQuery.data?.status.step_key_name

    useEffect(() => {
        if (
            showExperiencesHostVideoQuery.isSuccess &&
            !showExperiencesHostVideoQuery.data.has_friend_completed_onboarding
        ) {
            setOnboardingSlidingModalState('open')
        }
    }, [showExperiencesHostVideoQuery.isSuccess])

    if (showExperiencesHostVideoQuery.isPending) {
        return (
            <ExperiencesHostVideoPageLayout>
                <ExperiencesHostVideoHeader />
                <ExperiencesHostVideoBody justify="center">
                    <Spinner size={32} />
                </ExperiencesHostVideoBody>
            </ExperiencesHostVideoPageLayout>
        )
    }
    if (showExperiencesHostVideoQuery.isError) {
        return (
            <ExperiencesHostVideoPageLayout>
                <ExperiencesHostVideoHeader />
                <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
            </ExperiencesHostVideoPageLayout>
        )
    }
    if (
        !(
            (status == 'ready_to_film' && step == 'get_help') ||
            (status == 'ready_to_film' && step == 'schedule') ||
            (status == 'upload' && step == 'intro') ||
            (status == 'upload' && step == 'video_upload')
        )
    ) {
        return (
            <ExperiencesHostVideoPageLayout>
                <ExperiencesHostVideoHeader />
                <ErrorBox icon={<AlertCircleIcon />} title={t('experiences_host_video:friend_unavailable')} />
            </ExperiencesHostVideoPageLayout>
        )
    }
    if (status == 'upload' && step == 'intro') {
        return (
            <ExperiencesHostVideoPageLayout>
                <ExperiencesHostVideoHeader />
                <UploadClipsIntro />
            </ExperiencesHostVideoPageLayout>
        )
    }
    if (status == 'upload' && step == 'video_upload') {
        return (
            <ExperiencesHostVideoPageLayout>
                <ExperiencesHostVideoHeader />
                <UploadClips isFriend />
            </ExperiencesHostVideoPageLayout>
        )
    }

    const startQuiz = async () => {
        try {
            const response = await storeQuiz.run(
                httpStoreQuiz({
                    urlParams: urlParams,
                    params: {user_type: 'friend', response_type: 'extended'}
                })
            )
            setQuizId(response.data.id)
            setQuizSlidingModalState('open')
        } catch (error) {
            toast.error(t('errors:default'))
        }
    }

    return (
        <ExperiencesHostVideoPageLayout>
            <ExperiencesHostVideoHeader />
            <div>
                <ExperiencesHostVideoBody>
                    <Flexbox direction="column" gap={2}>
                        <ExperiencesHostVideoTitle>
                            {t('experiences_host_video:get_ready_to_film')}
                        </ExperiencesHostVideoTitle>
                        <ExperiencesHostVideoParagraph>
                            {t('experiences_host_video:get_ready_to_film_description')}
                        </ExperiencesHostVideoParagraph>
                    </Flexbox>
                    <Flexbox direction="column" gap={4}>
                        <ExperiencesHostVideoActionCard
                            onClick={() => setRequirementsPerspectiveSlidingModalState('open')}
                        >
                            <VideoRecorderIcon />
                            <Flexbox direction="column" gap={1}>
                                <h3>{t('experiences_host_video:requirements:title')}</h3>
                                <p>{t('experiences_host_video:requirements:cta_description')}</p>
                            </Flexbox>
                        </ExperiencesHostVideoActionCard>
                        <ExperiencesHostVideoActionCard disabled={storeQuiz.isLoading} onClick={startQuiz}>
                            {storeQuiz.isLoading ? (
                                <div>
                                    <Spinner />
                                </div>
                            ) : (
                                <AnnotationQuestionIcon />
                            )}
                            <Flexbox direction="column" gap={1}>
                                <h3>{t('experiences_host_video:take_a_quiz')}</h3>
                                <p>{t('experiences_host_video:take_a_quiz_description')}</p>
                            </Flexbox>
                        </ExperiencesHostVideoActionCard>
                    </Flexbox>
                </ExperiencesHostVideoBody>
                <Divider height={8} topSpacing={0} bottomSpacing={0} background={theme.palette.neutral['200']} />
                <ExperiencesHostVideoBody>
                    <StyledChecklistTitle>{t('experiences_host_video:checklist')}</StyledChecklistTitle>
                    <Checklist haveCheckbox answers={showExperiencesHostVideoQuery.data.answers} />
                </ExperiencesHostVideoBody>
            </div>
            <ExperiencesHostVideoFooter>
                <Button
                    fullWidth
                    disabled={updateStepMutation.isPending}
                    onClick={() => updateStepMutation.mutate({status: 'upload', step: 'intro'})}
                >
                    {t('experiences_host_video:upload')}
                </Button>
            </ExperiencesHostVideoFooter>

            {onboardingSlidingModalState != 'closed' && (
                <FriendOnboardingModal
                    answers={showExperiencesHostVideoQuery.data.answers}
                    slidingModalState={onboardingSlidingModalState}
                    setSlidingModalState={setOnboardingSlidingModalState}
                />
            )}
            {requirementsPerspectiveSlidingModalState != 'closed' && (
                <RequirementsPerspectiveModal
                    userType="friend"
                    slidingModalState={requirementsPerspectiveSlidingModalState}
                    setSlidingModalState={setRequirementsPerspectiveSlidingModalState}
                />
            )}
            {!!quizId && quizSlidingModalState != 'closed' && (
                <QuizModal
                    quizId={quizId}
                    slidingModalState={quizSlidingModalState}
                    setSlidingModalState={setQuizSlidingModalState}
                />
            )}
        </ExperiencesHostVideoPageLayout>
    )
}
