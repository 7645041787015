import {SlidingModalCloseButton, SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {FC, ReactElement, useState} from 'react'
import {StyledQuizModal, StyledModalHeader} from '@/features/experiences-host-video/components/quiz-modal/style.ts'
import {useTranslation} from '@/translations/i18n.tsx'
import {QuizModalPhoneStep} from '@/features/experiences-host-video/components/quiz-modal-phone-step/QuizModalPhoneStep.tsx'
import {QuizModalUploadStep} from '@/features/experiences-host-video/components/quiz-modal-upload-step/QuizModalUploadStep.tsx'
import {QuizModalHoldCameraStep} from '@/features/experiences-host-video/components/quiz-modal-hold-camera-step/QuizModalHoldCameraStep.tsx'
import {QuizModalStagedPeopleCountStep} from '@/features/experiences-host-video/components/quiz-modal-staged-people-count-step/QuizModalStagedPeopleCountStep.tsx'
import {QuizModalElementsStep} from '@/features/experiences-host-video/components/quiz-modal-elements-step/QuizModalElementsStep.tsx'
import {useShowQuiz} from '@/features/experiences-host-video/services/useShowQuiz.ts'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertCircleIcon} from '@components/ui/icon'
import {ModalBody} from '@components/ui/modal-atoms/ModalAtoms.tsx'

export type QuizStep = 'phone' | 'upload' | 'hold_camera' | 'staged_people_count' | 'elements'

interface QuizModalProps {
    quizId: number
    slidingModalState: SlidingModalState
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
}
export const QuizModal: FC<QuizModalProps> = ({slidingModalState, setSlidingModalState, quizId}) => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const [step, setStep] = useState<QuizStep>('phone')
    const quizQuery = useShowQuiz({urlParams: {...routeParams, quizId: quizId}})

    if (quizQuery.isPending) {
        return (
            <StyledQuizModal slidingModalState={slidingModalState}>
                <StyledModalHeader>
                    <SlidingModalCloseButton setSlidingModalState={setSlidingModalState} />
                    {t('experiences_host_video:quiz:title')}
                </StyledModalHeader>
                <ModalBody justify="center" align="center">
                    <Spinner size={32} />
                </ModalBody>
            </StyledQuizModal>
        )
    }
    if (quizQuery.isError) {
        return (
            <StyledQuizModal slidingModalState={slidingModalState}>
                <StyledModalHeader>
                    <SlidingModalCloseButton setSlidingModalState={setSlidingModalState} />
                    {t('experiences_host_video:quiz:title')}
                </StyledModalHeader>
                <ModalBody>
                    <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
                </ModalBody>
            </StyledQuizModal>
        )
    }

    const stepToComponent = {
        phone: <QuizModalPhoneStep quiz={quizQuery.data} setStep={setStep} />,
        upload: <QuizModalUploadStep quiz={quizQuery.data} setStep={setStep} />,
        hold_camera: <QuizModalHoldCameraStep quiz={quizQuery.data} setStep={setStep} />,
        staged_people_count: <QuizModalStagedPeopleCountStep quiz={quizQuery.data} setStep={setStep} />,
        elements: <QuizModalElementsStep quiz={quizQuery.data} setSlidingModalState={setSlidingModalState} />
    } as const satisfies Record<QuizStep, ReactElement>

    return (
        <StyledQuizModal slidingModalState={slidingModalState}>
            <StyledModalHeader>
                <SlidingModalCloseButton setSlidingModalState={setSlidingModalState} />
                {t('experiences_host_video:quiz:title')}
            </StyledModalHeader>
            {stepToComponent[step]}
        </StyledQuizModal>
    )
}
